.title {
    margin-top: 3rem;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 32px;
    font-weight: normal;
}

.subtitle {
    margin-top: 3rem;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 32px;
    font-weight: normal;
}

.button {
    background: linear-gradient(0deg, #3B4BF9, #3B4BF9)!important;
    border-radius: 1rem !important;
    font-weight: bold !important;
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    margin-top: 1rem !important;
    color: #FFF !important;
    font-size: 14px !important;
    text-transform: none !important;
    margin-top: 3rem !important;
    margin-bottom: 1rem !important;
    box-shadow: 0px 20px 40px -10px rgba(59, 75, 249, 0.4);
}
.subTitle{
    color: rgba(255, 255, 255, 0.603447);
    font-size:12px;
    font-weight:400;
    line-height:18px;
    width: 260px;
}