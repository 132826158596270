.profileEditorContainer {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.coverContainer {
  background-color: '#f0f';
  display: block;
  /* flex: 1; */
  overflow: hidden;
  width: 100%;
  max-height: 20rem;
}

.cover {
  vertical-align: middle;
  height: 35vh;
  /* flex:1; */
  /* resize: horizontal; */
  width: 100%;
}

.editCoverButtonContainer {
  position: absolute;
  /**
  Here we need to decide, if we want the button at the bottom of the image we must set a maxHeight for the
  img component otherwise we could put the button at the top
  **/
  top: 1rem;
  right: 1rem;
}

@media (max-width: 1420px) {
  .editCoverButtonContainer {
    top: 10rem;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .editCoverButtonContainer {
    top: 6rem;
  }
}

.profileContainer {
  background-color: #0D1021;
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 2rem;
  margin-top: -10%;
  padding: 60px 64px;
  width: 100%;
  max-width: 680px;
}

.profilePicContainer {
  display: flex;
}

.profilePic {
  max-width: 80px;
  max-height: 80px;
  border-radius: 100rem;
}

.streamerNameAndEditBioButtonContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.streamerNameContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-right: 10px;
}

.editBioButtonContainer {
  /* padding: 10rem; */
}

.streamerName {
  color: #FFF;
  align-self: center;
  font-size: 30px;
  font-weight: 500;
}

.founderBadgeContainer {
  align-self: center;
  margin-left: 8.5px;
}

.founderBadge {
  align-self: center;
  width: 25px;
  height: 25px;
}

.twitchURLContainer {
  background: linear-gradient(91deg, #881CEE -1.34%, #FF4BA2 50.99%, #FFBD8B 100.15%);
  padding: 2px;
  /* background-color: #202750; */
  display: flex;
  border-radius: 8px;
  margin-top: 42px;
  height: 28px;
  width: fit-content;
  overflow: hidden;
}

.twitchURLSubContainer {
  display: flex;
  background-color: #202750;
  border-radius: 8px;
}

.twitchURL {
  display: flex;
  color: #6C5DD3;
  padding: 0 14px;
  align-self: center;
  text-decoration: none;
}

.copyIconContainer {
  background-color: #2906A4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  cursor: pointer;
}

.copyIcon {
  height: 28px;
  width: 28px;
}

.bioContainer {
  margin-top: 2rem;
}

.bioText {
  color: #FFF;
  white-space: pre-line;
  cursor: pointer;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 3rem;
  margin-left: -0.25rem;
  width: fit-content;
  align-content: flex-start;
  justify-content: flex-start;
}

.tag {
  display: flex;
  margin: 0.3rem 0.2rem;
}

.showNextStreamsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-top: 2rem;
}

.showNextStreamsText {
  color: #FFF;
  display: flex;
  align-self: center;
}

.socialLinksContainer {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.socialLinkContainer {
  margin: 12px 0;
  margin-right: 5px;
  width: 100%;
}

.socialLinkLabel {
  color: #FFF !important;
  font-size: 1rem !important;
}

.socialLinkTextInput {
  margin-top: 0.8rem;
  width: 100%;
}