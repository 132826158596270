@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.cdnfonts.com/css/mechsuit);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Nav-Title {
  flex-grow: 1;
}

.White-Color {
  color: '#FFF';
  text-decoration: none;
  text-transform: none !important;
}

.Margin-Right {
  margin-left: 1rem;
}
body {
  font-family: 'Inter' !important;
}
p {
  font-family: 'Inter' !important;
  margin: 0;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #202750;
}

::-webkit-scrollbar-thumb {
  background: #4040FF;
}

::-webkit-scrollbar-thumb:hover {
  background: #8080FF;
}
.StreamersSignin_mainContainer__3B3uS{
    text-align: center;
}
.StreamersSignin_getStarted__3-gDJ {
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -1px;
    text-align: center;
    color: #FFF;
}

.StreamersSignin_formContainer__2K2VQ {
    margin-top: 95px;
}

.StreamersSignin_inviteCodeInput__3P9m8 {
    font-weight: bold !important;
    background-color: #141833;
    border-radius: .5rem;
    color: #FFF !important;
    margin-right: 8;
    font-size: 14px;
}

.StreamersSignin_continueButton__8FPVi {
    background: #8B46FF !important;
    border-radius: 20px !important;
    font-weight: bold !important;
    padding: 23px 36px !important;
    color: #FFF !important;
    font-size: 14px !important;
    text-transform: none !important;
}

.StreamersSignin_bottomImage__1dfDv {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

@media ( max-height: 832px ) {
   .StreamersSignin_bottomImage__1dfDv{
     display: none;  
    }
}
.StreamerDashboardContainer_container__1RP1F {
    /* background-color: #0D1021; */
}

.StreamerDashboardContainer_appBar__3GB3u {
    background-color: transparent !important;
    box-shadow: none !important;
}

.StreamerDashboardContainer_alreadyAUser__YO7Yx {
    color: #D8D8D8;
}

.StreamerDashboardContainer_buttonContainer__2va28 {
    margin-left: .5rem !important;
}

.StreamerDashboardContainer_button__1fGG9 {
    color: #5F75EE !important;
    border: 1px solid #5F75EE !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    text-transform: none !important;
    text-decoration: none !important;
}
.BuySubscriptionDialog_goPremiumFancyContainer__2UQot {
    background: linear-gradient(152.4deg, #690EFF 0%, #FF5862 100%);
    padding: 64px 56px 0px 32px;
    display: flex;
    flex-direction: column;
}

.BuySubscriptionDialog_goPremiumMainTitle__1FutA {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    max-width: 240px;
}

.BuySubscriptionDialog_goPremiumInfoContainer__3VbKl {
    display: flex;
    flex-direction: column;
    padding: 74px 64px 74px 50px;
    max-width: 300px;
}

.BuySubscriptionDialog_goPremiumCloseIconContainer__uL2nx {
    position: absolute;
    top: 24px;
    right: 24px;
    max-height: 40px;
    cursor: pointer;
}

.BuySubscriptionDialog_goPremiumHeading__Am-nC {
    color: #fff;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
}

.BuySubscriptionDialog_goPremiumCaption__2_uCJ {
    color: #FFFFFF99;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.BuySubscriptionDialog_goPremiumSubheading__2cRzz {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.BuySubscriptionDialog_goPremiumDescription__V5I2m {
    color: #FFFFFF99;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.BuySubscriptionDialog_goPremiumSoonBorder__2ioql {
    background: linear-gradient(141.89deg, #4657FF 0%, #8F4EFF 100%);
    padding: 1px;
    border-radius: 5px;
    margin-left: 8px;
}

.BuySubscriptionDialog_goPremiumSoonInnerContainer__2PINw {
    background-color: #141833;
    padding: 3px 5px;
    border-radius: 5px;
}

.BuySubscriptionDialog_goPremiumSoonText__1gdWp {
    color: #B88FFF;
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
}

.BuySubscriptionDialog_perksList__Pd7b9 {
    list-style: none;
    margin-top: 8px;
    padding-left: 8px;
  }

.BuySubscriptionDialog_perksList__Pd7b9 li::before {
    content: '\2022';
    color: rgba(255, 255, 255, .6);
    font-weight: bold;
    display: inline-block;
    width: 1em;
}
.StreamerOnBoarding_container__A4cfN {
    min-height: 100vh;
}

.StreamerOnBoarding_welcome__1UpzW {
    font-weight: bold;
    color: #FFF;
    letter-spacing: -1px;
    font-size: 40px;
    text-align: center;
}

.StreamerOnBoarding_card__2TkwC {
    background-color: #141833 !important;
    border-radius: 2rem !important;
}

.StreamerOnBoarding_playerWrapper__28gEy {
    position: relative;
    border-radius: 20px;
    padding: .5rem;
}

.StreamerOnBoarding_player__cqnG9 {
    border-radius: 10%;
    overflow: hidden;
}

.StreamerOnBoarding_formContainer__RaQa8 {
    margin-top: 3rem;
}

.StreamerOnBoarding_inviteCodeInput__1ciwQ {
    font-weight: bold !important;
    background-color: #141833;
    border-radius: .5rem;
    color: #FFF !important;
    margin-right: 8;
    font-size: 14px;
}

.StreamerOnBoarding_continueButton__3LPhy {
    margin-top: 1rem !important;
    border: 3px solid #5F75EE !important;
    border-radius: 1rem !important;
    font-size: 12px !important;
    color: #5C71E6 !important;
    text-align: center !important;
    text-transform: none !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    padding-top: .7rem !important;
    padding-bottom: .7rem !important;
}
body {
    overflow-x: hidden;
}

.StreamerProfile_header__3-DnB {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    max-width: 1000px;
    align-items: center;
    margin-bottom: 60px;
}

@media (max-width: 474.75px) {
    .StreamerProfile_header__3-DnB {
        margin-bottom: 80px;
    }
}

.StreamerProfile_avatarContainer__2hyvG {
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.StreamerProfile_streamerName__2xtKG {
    margin-left: 10px;
    color: #FFF;
    font-size: 24px;
}

.StreamerProfile_twitchButton__3-bzi {
    background: #8B46FF !important;
    border-radius: 10px !important;
    font-weight: 500 !important;
    color: #FFF !important;
    font-size: 13px !important;
    padding: 12px !important;
    text-transform: none !important;
    max-height: 48px !important;
    margin-right: 20px !important;
}

.StreamerProfile_qreatorCodeContainer__35QYu {
    padding-right: 25px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.StreamerProfile_qreatorCode__s6Bow {
    margin-left: 9px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #FFF;
}

.StreamerProfile_messagesButton__21kF3 {
    min-height: 72px;
    border-radius: 20px !important;
    font-weight: 500 !important;
    color: #FFF !important;
    font-size: 15px !important;
    padding: 24px 16px !important;
    text-transform: none !important;
    max-height: 48px !important;
}

@media (max-width: 766px) {
    .StreamerProfile_messagesButton__21kF3 {
        margin-left: 0px !important;
    }

    .StreamerProfile_header__3-DnB {
        margin-top: 12px;
    }
}

@media (max-width: 475px) {
    .StreamerProfile_header__3-DnB {
        margin-top: 16px;
    }
}

.StreamerProfile_displayFlex__2aJ3N {
    display: flex;
}

.StreamerProfile_balanceInfoContainer__7gYJk {
    flex-direction: row;
}

.StreamerProfile_balanceContainers__2C-1S {
    display: flex;
    background-color: #141735;
    /* padding: 22px 24px; */
    height: 100px;
    min-width: 180px !important;
    border-radius: 20px;
    align-items: center;
}

.StreamerProfile_bitBalanceContainer__Rg_9F {
    display: flex;
    background-color: #141735;
    height: 100px;
    min-width: 190px !important;
    border-radius: 20px;
    align-items: flex-end;
    cursor: pointer;
}

.StreamerProfile_containerBit__JWskt {
    width: 100%;
    display: flex;
    align-items: center;
}

@media (max-width: 1155px) {

    .StreamerProfile_containerBit__JWskt {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        height: 100%;
        margin-top: 20px;

    }
}

.StreamerProfile_balanceInnerContainer__3bAqd {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-end;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: right;
    max-width: 80%;
}

.StreamerProfile_balanceDataTextTitle__1_CG4 {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    color: #6C5DD3;
}

.StreamerProfile_balanceDataText__CejN- {
    display: flex;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.86px;
    font-weight: 600;
}

.StreamerProfile_cheersTitleContainer__3voG1 {
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.StreamerProfile_cheersText__21FDI {
    color: #FFF;
    text-align: center;
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
}

.StreamerProfile_getPremiumBannerContainer__3PkIW {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1.75rem;
    margin-left: -6rem;
    height: 3.5rem;
    width: 12;
    background: linear-gradient(90deg, #FFB906 0%, #FFE600 100%), #6C5DD3;
    z-index: 9999;
    border-radius: 16px;
    padding-right: .75rem;
    padding-left: .75rem;
    cursor: pointer;
}

.StreamerProfile_getPremiumBannerText__2AHJp {
    font-weight: bold;
    font-size: 14;
    color: #0D1021;
}

.StreamerProfile_balanceContainer__1jW0c {
    position: relative;
    margin-top: .5rem;
    border-radius: 20px;
    background-color: #141735;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.StreamerProfile_blur__1kyvb {
    -webkit-filter: blur(2.5px);
    -moz-filter: blur(2.5px);
    -o-filter: blur(2.5px);
    -ms-filter: blur(2.5px);
    filter: blur(2.5px);
}

.StreamerProfile_balanceCurrencyContainer__3xWcP {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2rem;
}

.StreamerProfile_balanceCurrencyValue__1k7Oh {
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
    margin-right: 1rem;
}

.StreamerProfile_historyAccordion__23Aa4 {
    background-color: #141735 !important;
    border-radius: 20px !important;
}

.StreamerProfile_historyText__1VG_5 {
    color: #6C5DD3;
    font-size: 15px;
    font-weight: 600;
    margin-right: 1rem;
}

.StreamerProfile_transactionContainer__1i0Ew {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 14px;
}

.StreamerProfile_transactionInformationContainer__3xci9 {
    margin-left: 24px;
    color: #FFF;
}

.StreamerProfile_transactionText__AWitJ {
    color: #FFF;
    font-weight: 600;
    font-size: 14px;
}

.StreamerProfile_transactionDate__1yXGf {
    font-weight: 500;
    font-size: 12px;
    opacity: .65;
    line-height: 0
}

.StreamerProfile_learnMoreContainer__10Mp2 {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.StreamerProfile_learnMoreText__24KFH {
    margin-left: .25rem;
    color: #FFF;
    opacity: .4;
    font-size: 16px;
    font-weight: 500;
    margin-right: 1rem;
}

.StreamerProfile_myStreamsContainer__2ZYcN {
    margin-top: 56px;
    margin-bottom: 20px;
    max-width: 1000px;
}

.StreamerProfile_reactionsContainer__1OqY- {
    margin-top: 56px;
    margin-bottom: 40px;
    max-width: 1000px;
}

@media (max-width: 565.45px) {
    .StreamerProfile_myStreamsContainer__2ZYcN {
        margin-top: 20px;
    }
}

.StreamerProfile_streamsCardContainer__3KZpc {
    min-height: 400px;
    margin-top: 42px !important;
}

.StreamerProfile_innerStreamsCardContainer__2Ox3l {
    min-height: 400px;
}

.StreamerProfile_title__39yOz {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -1px;
}

.StreamerProfile_subtitle__KZChB {
    color: #FFFFFF9A;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 32px;
}

@media (max-width: 528.18px) {
    .StreamerProfile_myStreamsContainer__2ZYcN {
        margin-top: 56px;
        margin-bottom: 6px;
    }
}

h1 {
    margin-block: 0;
}

.StreamerProfile_createEventCard__qXOhZ {
    display: flex;
    flex-direction: column;
    background-color: #141833 !important;
    border-radius: 1.5rem !important;
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.31) !important;
    height: 100%;
    max-width: 250px;
    min-width: 250px;
}

.StreamerProfile_cardContainer__1FjD7 {
    padding: 0px 16px !important;
    max-width: 270px !important;
    margin-bottom: 20px !important;
}

.StreamerProfile_newStream__2lcFZ {
    margin-left: 24px;
    margin-top: 24px;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

}

.StreamerProfile_createButton__3kVTs {
    margin-top: 5rem !important;
    margin-bottom: 7rem !important;
    padding: 1.5rem !important;
    width: 60px;
    height: 60px;
    background-color: #141833;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.29);
}

.StreamerProfile_createButton__3kVTs>.StreamerProfile_MuiIconButton-label__3uFjn {
    width: 18px;
    height: 18px;
}

.StreamerProfile_eventCard__WG2K0 {
    background-color: #141833 !important;
    border-radius: 1.5rem !important;
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.31) !important;
    height: 100%;
}

.StreamerProfile_eventCardContent__1uCfv {
    padding: 1.5rem;
    padding-top: .5rem;
    justify-content: space-between;
}

.StreamerProfile_eventCardTitle__1dWdD {
    font-size: 18px;
    color: #FFFFFF;
}

.StreamerProfile_rowContainer__23XnC {
    display: flex;
    align-items: center;
}

.StreamerProfile_circle__3B3Bl {
    background-color: #0049C6;
    border-radius: 100%;
    height: .55rem;
    width: .55rem;
    margin-right: .5rem;
}

.StreamerProfile_participantsNumber__2sBuw {
    font-size: 12px;
    color: #808191;
    text-align: right;
    line-height: 16px;
}

.StreamerProfile_dateContainer__3INdy {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.StreamerProfile_date__1W8NZ {
    color: #FFF;
    font-size: 13px;
    line-height: 20px;
    margin-left: .625rem;
}

.StreamerProfile_reactionsHeaderContainer__3MgX8 {
    display: flex;
    justify-content: space-between;
}

.StreamerProfile_switchContainer__PNcDi {
    display: flex;
    align-self: flex-end;
    align-items: center;
    margin-top: auto;
}

.StreamerProfile_miniInfoText__gKypK {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 32px;
    margin-left: 24px;
}

.StreamerProfile_reactionSettingContainer__3rLy- {
    display: flex;
    flex-direction: column;
    /* Safari/Chrome, other WebKit */
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    flex: 1 1;
    height: 140px;
    background-color: #141735;
    padding: 24px;
    border-radius: 20px;
}

.StreamerProfile_reactionSettingTitle__37sUo {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
}

.StreamerProfile_reactionSettingSubtitle__lmQ7Q {
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 4px;
    white-space: pre-wrap;
}

.StreamerProfile_costInput__3AaBe {
    display: flex;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    background-color: #0000;
    border: none;
    outline: none;
    height: 36px;
    border-radius: 8px;
    padding: 0px 8px;
    padding-left: 8px;
}

.StreamerProfile_costInput__3AaBe:focus {
    background-color: #14173544;
}

.StreamerProfile_editChannelRewardButton__86z7U {
    display: flex;
    border-radius: 8px;
    padding: 8px 8px;
    /* min-width: 20px; */
    justify-content: center;
    min-height: 16px;
}

.StreamerProfile_editChannelRewardButton__86z7U:hover {
    cursor: pointer;
}

.StreamerProfile_editChannelRewardButtonText__1FwOC {
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.492000013589859px;
    text-align: center;
}


.StreamsLeft_container__3B7uI {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.StreamsLeft_streamsLeftText__blYJU {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
}
.StreamsLeft_renovationText__268OR {
    text-align: right;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: #6C5DD3CC;
}

@media (max-width: 600px) {
    .StreamsLeft_container__3B7uI {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .StreamsLeft_streamsLeftText__blYJU {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
    }
    .StreamsLeft_renovationText__268OR {
        text-align: left;
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        color: #6C5DD3CC;
    }
}
.BarProgressBit_container__2Rmle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    cursor: pointer;
}

.BarProgressBit_barProgress__1mwyL {
    height: 57px;
}

.BarProgressBit_percentageTitle__3j7d5 {
    padding-bottom: 13px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #6c5dd3;
}

.BarProgressBit_titles__13u9B {
    display: flex;
    height: 34px;
    align-items: inherit;
}

.BarProgressBit_percentage__A_3Vh {
    margin: 2px 0px 0px 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.864px;
    color: #ffffff;
}

.BarProgressBit_available__29ePP p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #6c5dd3;
}

.BarProgressBit_available__29ePP h2 {
    margin: 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.864px;
    color: #ffffff;
}

.BarProgressBit_button__2wFWM {
    width: 110px;
    height: 40px;
    background: #3B4BF9;
    border-radius: 8px;
    color: #FFFFFF;
    border:none;
    outline: none;
    margin-left: 10px;
    cursor: pointer;
}

@media (max-width: 600px) {
    .BarProgressBit_container__2Rmle{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content:space-around;
        height:100%;
    }
    .BarProgressBit_button__2wFWM{
        width: 180px;
    }
 }

 .BarProgressBit_dialog__rXr2A{
    border-radius:40px;
 }
.CasthQutDialog_container_Dialog__JNgQx{
    display: flex;
    justify-content:center;
    align-items:center;
    width:347px;
    background-color: #141833;
    border-radius:40px;
}

.CasthQutDialog_container__2myP2{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;
    max-width: 250px;
    height:384px;
    background-color: #141833;
    border-radius: 40px;
    padding: 18px;
}
.CasthQutDialog_container__2myP2 h1 {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 48px;
line-height: 58px;
display: flex;
align-items: center;
text-align: right;
letter-spacing: -0.864px;
color: #FFFFFF;
}

.CasthQutDialog_calculated_Bit__Ms6kv{
 font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.35px;
color: #8692FF;
}

.CasthQutDialog_containe_text__2i38X{
width:250px;
}

.CasthQutDialog_text__3E8V1{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
text-align: center;
letter-spacing: -0.02em;
color: #FFFFFF;
}

.CasthQutDialog_button_cash__20tC-{
    margin-top: 10px;;
width:202px ;
height:56px;
 background: linear-gradient(0deg, #3B4BF9, #3B4BF9), #FF006B;
box-shadow: 0px 20px 40px -10px rgba(59, 75, 249, 0.4);
border-radius: 16px;
border: none;
cursor: pointer;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
letter-spacing: 0.492px;
color: #FFFFFF;
}

.CasthQutDialog_button_cancel__3d33p{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.492px;
color: #3B4BF9;
background:transparent;
border:none;
cursor: pointer;
}
.CasthQutConfirmDialog_container__3dkEd{
    width: 227px;
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.CasthQutConfirmDialog_container__3dkEd p{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
text-align: center;
letter-spacing: -0.02em;
color: #FFFFFF;
}

.CasthQutConfirmDialog_tick__151I_{
    width: 90px;
    height: 90px;
    left: calc(50% - 90px/2 - 0.5px);
    top: calc(50% - 90px/2 - 107px);
}

.CasthQutConfirmDialog_container__3dkEd button{
background: linear-gradient(0deg, #3B4BF9, #3B4BF9), #FF006B;
 box-shadow: 0px 20px 40px -10px rgba(59, 75, 249, 0.4);
border-radius: 16px;
height:56px;
width:202px;
 border:none;
 font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
letter-spacing: 0.492px;
color: #FFFFFF;
cursor: pointer;
}
.ModalQoinsDrops_container__1BHe8 {
  width: 100%;
  display: flex;
  align-items: center;
}

.ModalQoinsDrops_secctions_input__2bOnB {
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.ModalQoinsDrops_heading__1GOY- {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
}

.ModalQoinsDrops_subheading__LnZ0p {
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.603447);
}


.ModalQoinsDrops_content_input___9NGq label{
display: flex;

}

.ModalQoinsDrops_button__d9k0s {
  margin-top: 32px;
  border-radius: 16px;
  color: #0d1021;
  background-color: #00ffdd;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  box-shadow: 0px 20px 40px -10px rgba(0, 255, 221, 0.2);
}


.ModalQoinsDrops_extraData__3tSsF {
 font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
background: linear-gradient(90deg, #E5BAFF 0%, #FFFCC0 44.48%), #FFFFFF;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-align: end;
padding:30px;
}


.ModalQoinsDrops_container_loader__2oNOz{
  margin-top: 32px;
  display: flex;
  height:10px;
}

.ModalQoinsDrops_container_loader__2oNOz p{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: 1px;
-webkit-background-clip: text;
background-clip: text;
color: #ffffff;
margin-right:8px;
}


.ModalQoinsDrops_loader__198Iv:before,
.ModalQoinsDrops_loader__198Iv:after,
.ModalQoinsDrops_loader__198Iv {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: ModalQoinsDrops_load7__34WD- 1.8s infinite ease-in-out;
  animation: ModalQoinsDrops_load7__34WD- 1.8s infinite ease-in-out;
}
.ModalQoinsDrops_loader__198Iv {
  /* margin: 8em auto; */
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}
.ModalQoinsDrops_loader__198Iv:before {
  left: -3.5em;
}
.ModalQoinsDrops_loader__198Iv:after {
  left: 3.5em;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}
.ModalQoinsDrops_loader__198Iv:before,
.ModalQoinsDrops_loader__198Iv:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes ModalQoinsDrops_load7__34WD- {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #ffffff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #FFF;
  }
}
@keyframes ModalQoinsDrops_load7__34WD- {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #ffffff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #FFF;
  }
}

.ModalQoinsDrops_loading_End__2W76a{
  margin-top: 32px;
  display: flex;
  justify-content:center;
  align-items: center;
}

.ModalQoinsDrops_loading_End__2W76a p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  -webkit-background-clip: text;
  background-clip: text;
  color: #ffffff;
  padding: 4px 0px 0px 2px;
}

.ModalQoinsDrops_loading_End__2W76a img{
  width:30px;
  height:30px;
}

.ModalQoinsDrops_radioGroup__1V0wU {
  margin-top: 20px;
}
.ReactionCard_container__OfCiA {
    /* Safari/Chrome, other WebKit */
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    height: 342px;
    border-radius: 20px;
    overflow: hidden;
    padding: 24px;
    justify-content: space-between;
}

.ReactionCard_gradientContainer__v4TRi {
    padding: 4px;
    border-radius: 20px;
    /* Safari/Chrome, other WebKit */
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    flex: 1 1;
}

.ReactionCard_costInput__2lmc7 {
    display: flex;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    background-color: #0000;
    border: none;
    outline: none;
    height: 36px;
    border-radius: 8px;
    padding: 0px 8px;
    padding-left: 8px;
}

.ReactionCard_costInput__2lmc7:focus {
    background-color: #14173544;
}

.ReactionCard_button__IRThC {
    display: flex;
    border-radius: 8px;
    padding: 8px 8px;
    /* min-width: 20px; */
    justify-content: center;
    min-height: 16px;
}

.ReactionCard_button__IRThC:hover {
    cursor: pointer;
}

.ReactionCard_buttonText__2W6V3 {
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.492000013589859px;
    text-align: center;
}

.ReactionCard_title__2dT1c {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
}

.ReactionCard_subtitle__1NP7k {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
}
.NewStream_title__25IcM {
    margin-top: 3rem;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 32px;
    font-weight: normal;
}

.NewStream_subtitle__FpO2v {
    margin-top: 3rem;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 32px;
    font-weight: normal;
}

.NewStream_button__1_uTl {
    background: linear-gradient(0deg, #3B4BF9, #3B4BF9)!important;
    border-radius: 1rem !important;
    font-weight: bold !important;
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    margin-top: 1rem !important;
    color: #FFF !important;
    font-size: 14px !important;
    text-transform: none !important;
    margin-top: 3rem !important;
    margin-bottom: 1rem !important;
    box-shadow: 0px 20px 40px -10px rgba(59, 75, 249, 0.4);
}
.NewStream_subTitle__3QLWS{
    color: rgba(255, 255, 255, 0.603447);
    font-size:12px;
    font-weight:400;
    line-height:18px;
    width: 260px;
}
.ChatBubbleiOS_paragrapChatBubble__3Iu5W {
  word-wrap: break-word;
  margin-bottom: 0px;
  line-height: 39px;
  position: relative;
	padding: 24px 27px;
  border-radius: 25px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: .35px;
  box-sizing: border-box;
  width: 'fit-content';
}

.ChatBubbleiOS_paragrapChatBubble__3Iu5W:before {
    content: "";
		position: absolute;
    bottom: 0;
    height: 25px;
  }

.ChatBubbleiOS_paragrapChatBubble__3Iu5W:after {
    content: "";
		position: absolute;
    bottom: 0;
    height: 25px;
  }

.ChatBubbleiOS_tailRight__3ztk7 {
	color: var(--text-color);
	background: var(--bubble-color);
	align-self: flex-end;
}

.ChatBubbleiOS_tailRight__3ztk7::before {
    right: -7px;
    width: 20px;
    background-color: var(--bubble-color);
    border-bottom-left-radius: 16px 14px;
    -webkit-clip-path: polygon(0 0, 65% 0, 65% 70%, 75% 85%, 90% 97%, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 65% 0, 65% 70%, 75% 85%, 90% 97%, 100% 100%, 0 100%);
}
.ChatBubbleiOS_tailLeft__2XifL {
	background: var(--bubble-color);
	color: var(--text-color);
  align-self: flex-start;
}
.ChatBubbleiOS_tailLeft__2XifL:before {
    left: -7px;
    width: 20px;
    background-color: var(--bubble-color);
    border-bottom-right-radius: 16px 14px;
    -webkit-clip-path: polygon(35% 0, 35% 70%, 25% 85%, 10% 97%, 0 100%, 100% 100%);
            clip-path: polygon(35% 0, 35% 70%, 25% 85%, 10% 97%, 0 100%, 100% 100%);
}
.StreamerProfileEditorOnBoarding_profileOnBoardingContainer__jfD1m {
    background: conic-gradient(from 134.88deg at 50.55% 49.24%, #5600E1 -61.47deg, #373FFF 26.68deg, #A534FE 167.74deg, #B518FF 197.3deg, #5600E1 298.53deg, #373FFF 386.68deg), linear-gradient(0deg, rgba(3, 7, 34, 0.95), rgba(3, 7, 34, 0.95));
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

.StreamerProfileEditorOnBoarding_profileOnBoardingModalContainer__2khwX {
    display: flex;
    flex-direction: column;
    background-color: #141833;
    width: 450px;
    height: 570px;
    border-radius: 35px;
    overflow: hidden;
    align-items: center;
}

.StreamerProfileEditorOnBoarding_modalImgContainer__34CgR {
    display: flex;
    justify-content: center;
    width: 100%;
}

.StreamerProfileEditorOnBoarding_modalText__1lPxX {
    display: flex;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
}

.StreamerProfileEditorOnBoarding_modalTextHeader__3IV_B {
    font-weight: 700;
    line-height: 32px;
}

.StreamerProfileEditorOnBoarding_modalTextParagraph__3YxBK {
    font-size: 14px;
    line-height: 22px;
    color: #FFF;
    width: 342px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    max-width: 80%;
}

.StreamerProfileEditorOnBoarding_modalButton__3IqcB {
    display: flex;
    width: 390px;
}

.StreamerProfileEditorOnBoarding_modalButtonPresentation__2AaVg {
    margin-top: auto !important;
    margin-bottom: 30px !important;
}

.StreamerProfileEditorOnBoarding_modalButtonContinue__2S2Pc {
    margin-bottom: 30px !important;
}

.StreamerProfileEditorOnBoarding_modalButtonEditing__3-VLn {
}

.StreamerProfileEditorOnBoarding_modalButtonForm__1Xo1T {
    height: 49px;
}
.StreamerProfileEditorOnBoarding_modalButtonFormLater__3p3Gm {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
    width: 220px;
    background-color: #0000 !important;
    box-shadow: none !important;
    color: #FFFFFF99 !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}

.StreamerProfileEditorOnBoarding_modalButtonFormContinue__2Wag1 {
    margin-bottom: 30px;
    margin-bottom: 24px !important;
    margin-top: auto !important;
}

.StreamerProfileEditorOnBoarding_modalTextSubParagraph__KHXJ2 {
    color: #FFFFFF99;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.StreamerProfileEditorOnBoarding_modalButtonActiveIndicator__1h_rR {
    display: flex;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
}

.StreamerProfileEditorOnBoarding_modalBioTextInputContainer__1pgmy {
    display: flex;
    flex: 1 1;
}

.StreamerProfileEditorOnBoarding_modalBioEditorContainer__1YNId {
    display: flex;
    flex-direction: column;
    background-color: #202750;
    border-radius: 15px;
    width: 390px;
    height: 234px;
    margin-top: 20px;
    overflow: hidden;
}

.StreamerProfileEditorOnBoarding_modalBioTextInput__EBejL {
    background-color: #202750;
    margin-top: 0 !important;
    padding-right: 16px !important;
    align-items: flex-start !important;
}

.StreamerProfileEditorOnBoarding_modalBioEditorFormatterButtonsContainer__3ock8 {
    display: flex;
    height: 46px;
    width: 100%;
}

.StreamerProfileEditorOnBoarding_modalTagSearchContainer__1mP_D {
    height: 45px;
    min-height: 45px;
    border-radius: 8px;
}

.StreamerProfileEditorOnBoarding_modalTagSearchTextInput__3baHp {
    color: #6C5DD3 !important;
}

.StreamerProfileEditorOnBoarding_modalTagsList__3YB_6 {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-left: -0.25rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 390px;
    align-content: flex-start;
    justify-content: flex-start;
}

.StreamerProfileEditorOnBoarding_modalTag__5AJTE {
    display: flex;
    margin: 0.3rem 0.2rem;
}

.StreamerProfileEditorOnBoarding_minLengthIndicator__38W17 {
    margin-top: auto !important;
    margin-bottom: 36px;
    font-size: 10px;
    font-weight: 400;
    width: 90%;
    color: #FFF;
    opacity: .6;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.StreamerProfileEditorOnBoarding_headerText__s2AL- {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;    
}

.StreamerProfileEditorOnBoarding_subText__3nENU{
    color: #FFFFFFCC;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    max-width: 340px;
}

.StreamerProfileEditorOnBoarding_subTextMartinTop__1t_XP {
    margin-top: 16px;
}

.StreamerProfileEditorOnBoarding_alignTextCenter__3DEPT {
    text-align: center;
}

.StreamerProfileEditorOnBoarding_createLinkContainer__1OnI_ {
    display: flex;
    margin-top: 52px;
    align-items: center;
}

.StreamerProfileEditorOnBoarding_createLinkFieldContainer__1XK70 {
    margin-left: 8px;
    background: linear-gradient(91deg, #881CEE -1.34%, #FF4BA2 50.99%, #FFBD8B 100.15%);
    padding: 2px;
    display: flex;
    border-radius: 8px;
    height: 28px;
    width: 200px;
    overflow: hidden;
}

.StreamerProfileEditorOnBoarding_createLinkFieldInnerConainer__27yaH {
    display: flex;
    flex: 1 1;
    background-color: #202750;
    border-radius: 8px;
}

.StreamerProfileEditorOnBoarding_createLinkText__2V7Q9 {
    background-color: transparent;
    color: #00FFDD;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    border: none;
    outline: none;
    padding: 0px 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


input[type=number] {
    -moz-appearance: textfield;
}

input::-webkit-input-placeholder {
    color: #00FFDD;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
}

input::placeholder {
    color: #00FFDD;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
}

.StreamerProfileEditorOnBoarding_finalListText__3CkQl {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
}

.StreamerProfileEditorOnBoarding_twitchURLContainer__2x-Lf {
    background: linear-gradient(91deg, #881CEE -1.34%, #FF4BA2 50.99%, #FFBD8B 100.15%);
    padding: 2px;
    display: flex;
    border-radius: 8px;
    margin-top: 53px;
    height: 28px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    overflow: hidden;
  }

  .StreamerProfileEditorOnBoarding_twitchURLSubContainer__LlqNR {
    display: flex;
    background-color: #202750;
    border-radius: 8px;
  }

  .StreamerProfileEditorOnBoarding_twitchURL__3IkP5 {
    display: flex;
    color: #00FFDD;
    padding: 0 14px;
    align-self: center;
    text-decoration: none;
  }

  .StreamerProfileEditorOnBoarding_copyIconContainer__33XVG {
    background-color: #2906A4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    cursor: pointer;
  }

  .StreamerProfileEditorOnBoarding_copyIcon__3_6VI {
    height: 28px;
    width: 28px;
  }
.StreamerProfileEditor_profileEditorContainer__30rPQ {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.StreamerProfileEditor_coverContainer__1Kdz- {
  background-color: '#f0f';
  display: block;
  /* flex: 1; */
  overflow: hidden;
  width: 100%;
  max-height: 20rem;
}

.StreamerProfileEditor_cover__3w87I {
  vertical-align: middle;
  height: 35vh;
  /* flex:1; */
  /* resize: horizontal; */
  width: 100%;
}

.StreamerProfileEditor_editCoverButtonContainer__1sdfm {
  position: absolute;
  /**
  Here we need to decide, if we want the button at the bottom of the image we must set a maxHeight for the
  img component otherwise we could put the button at the top
  **/
  top: 1rem;
  right: 1rem;
}

@media (max-width: 1420px) {
  .StreamerProfileEditor_editCoverButtonContainer__1sdfm {
    top: 10rem;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .StreamerProfileEditor_editCoverButtonContainer__1sdfm {
    top: 6rem;
  }
}

.StreamerProfileEditor_profileContainer___uOec {
  background-color: #0D1021;
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 2rem;
  margin-top: -10%;
  padding: 60px 64px;
  width: 100%;
  max-width: 680px;
}

.StreamerProfileEditor_profilePicContainer__1w4Vv {
  display: flex;
}

.StreamerProfileEditor_profilePic__1pU_E {
  max-width: 80px;
  max-height: 80px;
  border-radius: 100rem;
}

.StreamerProfileEditor_streamerNameAndEditBioButtonContainer__11eOK {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
}

.StreamerProfileEditor_streamerNameContainer__16n0k {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-right: 10px;
}

.StreamerProfileEditor_editBioButtonContainer__3t-jr {
  /* padding: 10rem; */
}

.StreamerProfileEditor_streamerName__1cU_p {
  color: #FFF;
  align-self: center;
  font-size: 30px;
  font-weight: 500;
}

.StreamerProfileEditor_founderBadgeContainer__2ye7M {
  align-self: center;
  margin-left: 8.5px;
}

.StreamerProfileEditor_founderBadge__2TpCC {
  align-self: center;
  width: 25px;
  height: 25px;
}

.StreamerProfileEditor_twitchURLContainer__cUxRw {
  background: linear-gradient(91deg, #881CEE -1.34%, #FF4BA2 50.99%, #FFBD8B 100.15%);
  padding: 2px;
  /* background-color: #202750; */
  display: flex;
  border-radius: 8px;
  margin-top: 42px;
  height: 28px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  overflow: hidden;
}

.StreamerProfileEditor_twitchURLSubContainer__3FcL9 {
  display: flex;
  background-color: #202750;
  border-radius: 8px;
}

.StreamerProfileEditor_twitchURL__3jRDS {
  display: flex;
  color: #6C5DD3;
  padding: 0 14px;
  align-self: center;
  text-decoration: none;
}

.StreamerProfileEditor_copyIconContainer__1P5ZS {
  background-color: #2906A4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  cursor: pointer;
}

.StreamerProfileEditor_copyIcon__RmMuy {
  height: 28px;
  width: 28px;
}

.StreamerProfileEditor_bioContainer__2LSx7 {
  margin-top: 2rem;
}

.StreamerProfileEditor_bioText__1R3ur {
  color: #FFF;
  white-space: pre-line;
  cursor: pointer;
}

.StreamerProfileEditor_tagsList__zhx0d {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 3rem;
  margin-left: -0.25rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-content: flex-start;
  justify-content: flex-start;
}

.StreamerProfileEditor_tag__2HXMq {
  display: flex;
  margin: 0.3rem 0.2rem;
}

.StreamerProfileEditor_showNextStreamsContainer__2ABES {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-top: 2rem;
}

.StreamerProfileEditor_showNextStreamsText__3CTTy {
  color: #FFF;
  display: flex;
  align-self: center;
}

.StreamerProfileEditor_socialLinksContainer__2LXPo {
  display: flex;
  flex-direction: column;
  margin-top: 48px;
}

.StreamerProfileEditor_socialLinkContainer__25bOK {
  margin: 12px 0;
  margin-right: 5px;
  width: 100%;
}

.StreamerProfileEditor_socialLinkLabel__2v6u_ {
  color: #FFF !important;
  font-size: 1rem !important;
}

.StreamerProfileEditor_socialLinkTextInput__C46A7 {
  margin-top: 0.8rem;
  width: 100%;
}
.OnBoarding_headerText__1-4l4 {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-wrap;
}

ul {
    margin-top: 32px;
}

li {
   
}

.OnBoarding_subText__2Iimo{
    color: #FFFFFFCC;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    max-width: 337px;
}

.OnBoarding_subTextMartinTop__1U--6 {
    margin-top: 16px;
}

.OnBoarding_liMargin__2Cb90 {
    margin-top: 18px;
}

.OnBoarding_watchConfigButton__2d5Gd {
    margin-top: 24px !important;
    border-radius: 8px !important;
    padding: 8px 16px !important;
    background-color: #3B4BF9 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #FFF !important;
    text-transform: none !important;
}
.OnBoarding_watchConfigButton__2d5Gd:hover {
    background-color: #3B4BF9 !important;
    opacity: .9 !important;
}

.OnBoarding_qoinsMainContainer__1TrXq {
    display: flex;
    width: 10ch;
    height: 34px;
    border-radius: 8px;
    background: linear-gradient(91deg, #881CEE -1.34%, #FF4BA2 50.99%, #FFBD8B 100.15%);
    padding: 2px;
}

.OnBoarding_qoinsSubContainer__3cxHm {
    display: flex;
    width: 10ch;
    flex: 1 1;
    border-radius: 6px;
    background-color: #202750;
    justify-content: center;
    align-items: center;
}

.OnBoarding_qoins__ORURz {
    background-color: transparent;
    color: #00FFDD;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    width: 8ch;
    text-align: center;
    border: none;
    outline: none;
}

.OnBoarding_alignTextCenter__2Mqzc {
    text-align: center;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


input[type=number] {
    -moz-appearance: textfield;
}  

.OnBoarding_gradientText__3WFmP {
    background: linear-gradient(227.05deg, #FFD3FB 9.95%, #F5FFCB 48.86%, #9FFFDD 90.28%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1px;
    max-width: 310px;
    text-align: center;
}

.OnBoarding_overlayResText__3xcEY {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.OnBoarding_overlayResNumbContainer__NsXuE {
    background-color: #202750;
    padding: 4px 16px;
    margin-left: 11px;
    border-radius: 6px;
}

.OnBoarding_overlayResNumb__2YmzG {
    color: #00FFDD;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
}

.OnBoarding_zapPriceContainer__3b-EW {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    gap: 16px;
}
