.goPremiumFancyContainer {
    background: linear-gradient(152.4deg, #690EFF 0%, #FF5862 100%);
    padding: 64px 56px 0px 32px;
    display: flex;
    flex-direction: column;
}

.goPremiumMainTitle {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    max-width: 240px;
}

.goPremiumInfoContainer {
    display: flex;
    flex-direction: column;
    padding: 74px 64px 74px 50px;
    max-width: 300px;
}

.goPremiumCloseIconContainer {
    position: absolute;
    top: 24px;
    right: 24px;
    max-height: 40px;
    cursor: pointer;
}

.goPremiumHeading {
    color: #fff;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
}

.goPremiumCaption {
    color: #FFFFFF99;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.goPremiumSubheading {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
}

.goPremiumDescription {
    color: #FFFFFF99;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.goPremiumSoonBorder {
    background: linear-gradient(141.89deg, #4657FF 0%, #8F4EFF 100%);
    padding: 1px;
    border-radius: 5px;
    margin-left: 8px;
}

.goPremiumSoonInnerContainer {
    background-color: #141833;
    padding: 3px 5px;
    border-radius: 5px;
}

.goPremiumSoonText {
    color: #B88FFF;
    font-size: 8px;
    font-weight: 400;
    line-height: 10px;
}

.perksList {
    list-style: none;
    margin-top: 8px;
    padding-left: 8px;
  }

.perksList li::before {
    content: '\2022';
    color: rgba(255, 255, 255, .6);
    font-weight: bold;
    display: inline-block;
    width: 1em;
}