.paragrapChatBubble {
  word-wrap: break-word;
  margin-bottom: 0px;
  line-height: 39px;
  position: relative;
	padding: 24px 27px;
  border-radius: 25px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: .35px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 'fit-content';
}

.paragrapChatBubble:before {
    content: "";
		position: absolute;
    bottom: 0;
    height: 25px;
  }

.paragrapChatBubble:after {
    content: "";
		position: absolute;
    bottom: 0;
    height: 25px;
  }

.tailRight {
	color: var(--text-color);
	background: var(--bubble-color);
	align-self: flex-end;
}

.tailRight::before {
    right: -7px;
    width: 20px;
    background-color: var(--bubble-color);
    border-bottom-left-radius: 16px 14px;
    clip-path: polygon(0 0, 65% 0, 65% 70%, 75% 85%, 90% 97%, 100% 100%, 0 100%);
}
.tailLeft {
	background: var(--bubble-color);
	color: var(--text-color);
  align-self: flex-start;
}
.tailLeft:before {
    left: -7px;
    width: 20px;
    background-color: var(--bubble-color);
    border-bottom-right-radius: 16px 14px;
    clip-path: polygon(35% 0, 35% 70%, 25% 85%, 10% 97%, 0 100%, 100% 100%);
}