body {
    overflow-x: hidden;
}

.header {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1000px;
    align-items: center;
    margin-bottom: 60px;
}

@media (max-width: 474.75px) {
    .header {
        margin-bottom: 80px;
    }
}

.avatarContainer {
    display: flex;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.streamerName {
    margin-left: 10px;
    color: #FFF;
    font-size: 24px;
}

.twitchButton {
    background: #8B46FF !important;
    border-radius: 10px !important;
    font-weight: 500 !important;
    color: #FFF !important;
    font-size: 13px !important;
    padding: 12px !important;
    text-transform: none !important;
    max-height: 48px !important;
    margin-right: 20px !important;
}

.qreatorCodeContainer {
    padding-right: 25px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.qreatorCode {
    margin-left: 9px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    color: #FFF;
}

.messagesButton {
    min-height: 72px;
    border-radius: 20px !important;
    font-weight: 500 !important;
    color: #FFF !important;
    font-size: 15px !important;
    padding: 24px 16px !important;
    text-transform: none !important;
    max-height: 48px !important;
}

@media (max-width: 766px) {
    .messagesButton {
        margin-left: 0px !important;
    }

    .header {
        margin-top: 12px;
    }
}

@media (max-width: 475px) {
    .header {
        margin-top: 16px;
    }
}

.displayFlex {
    display: flex;
}

.balanceInfoContainer {
    flex-direction: row;
}

.balanceContainers {
    display: flex;
    background-color: #141735;
    /* padding: 22px 24px; */
    height: 100px;
    min-width: 180px !important;
    border-radius: 20px;
    align-items: center;
}

.bitBalanceContainer {
    display: flex;
    background-color: #141735;
    height: 100px;
    min-width: 190px !important;
    border-radius: 20px;
    align-items: flex-end;
    cursor: pointer;
}

.containerBit {
    width: 100%;
    display: flex;
    align-items: center;
}

@media (max-width: 1155px) {

    .containerBit {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        height: 100%;
        margin-top: 20px;

    }
}

.balanceInnerContainer {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-end;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: right;
    max-width: 80%;
}

.balanceDataTextTitle {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    color: #6C5DD3;
}

.balanceDataText {
    display: flex;
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.86px;
    font-weight: 600;
}

.cheersTitleContainer {
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.cheersText {
    color: #FFF;
    text-align: center;
    font-weight: 500;
    padding-top: 8px;
    padding-bottom: 8px;
}

.getPremiumBannerContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1.75rem;
    margin-left: -6rem;
    height: 3.5rem;
    width: 12;
    background: linear-gradient(90deg, #FFB906 0%, #FFE600 100%), #6C5DD3;
    z-index: 9999;
    border-radius: 16px;
    padding-right: .75rem;
    padding-left: .75rem;
    cursor: pointer;
}

.getPremiumBannerText {
    font-weight: bold;
    font-size: 14;
    color: #0D1021;
}

.balanceContainer {
    position: relative;
    margin-top: .5rem;
    border-radius: 20px;
    background-color: #141735;
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blur {
    -webkit-filter: blur(2.5px);
    -moz-filter: blur(2.5px);
    -o-filter: blur(2.5px);
    -ms-filter: blur(2.5px);
    filter: blur(2.5px);
}

.balanceCurrencyContainer {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2rem;
}

.balanceCurrencyValue {
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
    margin-right: 1rem;
}

.historyAccordion {
    background-color: #141735 !important;
    border-radius: 20px !important;
}

.historyText {
    color: #6C5DD3;
    font-size: 15px;
    font-weight: 600;
    margin-right: 1rem;
}

.transactionContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 14px;
}

.transactionInformationContainer {
    margin-left: 24px;
    color: #FFF;
}

.transactionText {
    color: #FFF;
    font-weight: 600;
    font-size: 14px;
}

.transactionDate {
    font-weight: 500;
    font-size: 12px;
    opacity: .65;
    line-height: 0
}

.learnMoreContainer {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.learnMoreText {
    margin-left: .25rem;
    color: #FFF;
    opacity: .4;
    font-size: 16px;
    font-weight: 500;
    margin-right: 1rem;
}

.myStreamsContainer {
    margin-top: 56px;
    margin-bottom: 20px;
    max-width: 1000px;
}

.reactionsContainer {
    margin-top: 56px;
    margin-bottom: 40px;
    max-width: 1000px;
}

@media (max-width: 565.45px) {
    .myStreamsContainer {
        margin-top: 20px;
    }
}

.streamsCardContainer {
    min-height: 400px;
    margin-top: 42px !important;
}

.innerStreamsCardContainer {
    min-height: 400px;
}

.title {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -1px;
}

.subtitle {
    color: #FFFFFF9A;
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 32px;
}

@media (max-width: 528.18px) {
    .myStreamsContainer {
        margin-top: 56px;
        margin-bottom: 6px;
    }
}

h1 {
    margin-block: 0;
}

.createEventCard {
    display: flex;
    flex-direction: column;
    background-color: #141833 !important;
    border-radius: 1.5rem !important;
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.31) !important;
    height: 100%;
    max-width: 250px;
    min-width: 250px;
}

.cardContainer {
    padding: 0px 16px !important;
    max-width: 270px !important;
    margin-bottom: 20px !important;
}

.newStream {
    margin-left: 24px;
    margin-top: 24px;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;

}

.createButton {
    margin-top: 5rem !important;
    margin-bottom: 7rem !important;
    padding: 1.5rem !important;
    width: 60px;
    height: 60px;
    background-color: #141833;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.29);
}

.createButton>.MuiIconButton-label {
    width: 18px;
    height: 18px;
}

.eventCard {
    background-color: #141833 !important;
    border-radius: 1.5rem !important;
    box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.31) !important;
    height: 100%;
}

.eventCardContent {
    padding: 1.5rem;
    padding-top: .5rem;
    justify-content: space-between;
}

.eventCardTitle {
    font-size: 18px;
    color: #FFFFFF;
}

.rowContainer {
    display: flex;
    align-items: center;
}

.circle {
    background-color: #0049C6;
    border-radius: 100%;
    height: .55rem;
    width: .55rem;
    margin-right: .5rem;
}

.participantsNumber {
    font-size: 12px;
    color: #808191;
    text-align: right;
    line-height: 16px;
}

.dateContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.date {
    color: #FFF;
    font-size: 13px;
    line-height: 20px;
    margin-left: .625rem;
}

.reactionsHeaderContainer {
    display: flex;
    justify-content: space-between;
}

.switchContainer {
    display: flex;
    align-self: flex-end;
    align-items: center;
    margin-top: auto;
}

.miniInfoText {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 32px;
    margin-left: 24px;
}

.reactionSettingContainer {
    display: flex;
    flex-direction: column;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    flex: 1;
    height: 140px;
    background-color: #141735;
    padding: 24px;
    border-radius: 20px;
}

.reactionSettingTitle {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
}

.reactionSettingSubtitle {
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 4px;
    white-space: pre-wrap;
}

.costInput {
    display: flex;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    background-color: #0000;
    border: none;
    outline: none;
    height: 36px;
    border-radius: 8px;
    padding: 0px 8px;
    padding-left: 8px;
}

.costInput:focus {
    background-color: #14173544;
}

.editChannelRewardButton {
    display: flex;
    border-radius: 8px;
    padding: 8px 8px;
    /* min-width: 20px; */
    justify-content: center;
    min-height: 16px;
}

.editChannelRewardButton:hover {
    cursor: pointer;
}

.editChannelRewardButtonText {
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.492000013589859px;
    text-align: center;
}
