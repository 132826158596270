.container_Dialog{
    display: flex;
    justify-content:center;
    align-items:center;
    width:347px;
    background-color: #141833;
    border-radius:40px;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;
    max-width: 250px;
    height:384px;
    background-color: #141833;
    border-radius: 40px;
    padding: 18px;
}
.container h1 {
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 48px;
line-height: 58px;
display: flex;
align-items: center;
text-align: right;
letter-spacing: -0.864px;
color: #FFFFFF;
}

.calculated_Bit{
 font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
letter-spacing: 0.35px;
color: #8692FF;
}

.containe_text{
width:250px;
}

.text{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 24px;
text-align: center;
letter-spacing: -0.02em;
color: #FFFFFF;
}

.button_cash{
    margin-top: 10px;;
width:202px ;
height:56px;
 background: linear-gradient(0deg, #3B4BF9, #3B4BF9), #FF006B;
box-shadow: 0px 20px 40px -10px rgba(59, 75, 249, 0.4);
border-radius: 16px;
border: none;
cursor: pointer;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 22px;
letter-spacing: 0.492px;
color: #FFFFFF;
}

.button_cancel{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.492px;
color: #3B4BF9;
background:transparent;
border:none;
cursor: pointer;
}