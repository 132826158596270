.mainContainer{
    text-align: center;
}
.getStarted {
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -1px;
    text-align: center;
    color: #FFF;
}

.formContainer {
    margin-top: 95px;
}

.inviteCodeInput {
    font-weight: bold !important;
    background-color: #141833;
    border-radius: .5rem;
    color: #FFF !important;
    margin-right: 8;
    font-size: 14px;
}

.continueButton {
    background: #8B46FF !important;
    border-radius: 20px !important;
    font-weight: bold !important;
    padding: 23px 36px !important;
    color: #FFF !important;
    font-size: 14px !important;
    text-transform: none !important;
}

.bottomImage {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

@media ( max-height: 832px ) {
   .bottomImage{
     display: none;  
    }
}