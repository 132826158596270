.container {
  width: 100%;
  display: flex;
  align-items: center;
}

.secctions_input {
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.heading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
}

.subheading {
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.603447);
}


.content_input label{
display: flex;

}

.button {
  margin-top: 32px;
  border-radius: 16px;
  color: #0d1021;
  background-color: #00ffdd;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  box-shadow: 0px 20px 40px -10px rgba(0, 255, 221, 0.2);
}


.extraData {
 font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
background: linear-gradient(90deg, #E5BAFF 0%, #FFFCC0 44.48%), #FFFFFF;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-align: end;
padding:30px;
}


.container_loader{
  margin-top: 32px;
  display: flex;
  height:10px;
}

.container_loader p{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
display: flex;
align-items: center;
letter-spacing: 1px;
-webkit-background-clip: text;
background-clip: text;
color: #ffffff;
margin-right:8px;
}


.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  /* margin: 8em auto; */
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}
.loader:before {
  left: -3.5em;
}
.loader:after {
  left: 3.5em;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #ffffff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #FFF;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #ffffff;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #FFF;
  }
}

.loading_End{
  margin-top: 32px;
  display: flex;
  justify-content:center;
  align-items: center;
}

.loading_End p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  -webkit-background-clip: text;
  background-clip: text;
  color: #ffffff;
  padding: 4px 0px 0px 2px;
}

.loading_End img{
  width:30px;
  height:30px;
}

.radioGroup {
  margin-top: 20px;
}