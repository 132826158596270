.headerText {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    white-space: pre-wrap;
}

ul {
    margin-top: 32px;
}

li {
   
}

.subText{
    color: #FFFFFFCC;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    max-width: 337px;
}

.subTextMartinTop {
    margin-top: 16px;
}

.liMargin {
    margin-top: 18px;
}

.watchConfigButton {
    margin-top: 24px !important;
    border-radius: 8px !important;
    padding: 8px 16px !important;
    background-color: #3B4BF9 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #FFF !important;
    text-transform: none !important;
}
.watchConfigButton:hover {
    background-color: #3B4BF9 !important;
    opacity: .9 !important;
}

.qoinsMainContainer {
    display: flex;
    width: 10ch;
    height: 34px;
    border-radius: 8px;
    background: linear-gradient(91deg, #881CEE -1.34%, #FF4BA2 50.99%, #FFBD8B 100.15%);
    padding: 2px;
}

.qoinsSubContainer {
    display: flex;
    width: 10ch;
    flex: 1;
    border-radius: 6px;
    background-color: #202750;
    justify-content: center;
    align-items: center;
}

.qoins {
    background-color: transparent;
    color: #00FFDD;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    width: 8ch;
    text-align: center;
    border: none;
    outline: none;
}

.alignTextCenter {
    text-align: center;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


input[type=number] {
    -moz-appearance: textfield;
}  

.gradientText {
    background: linear-gradient(227.05deg, #FFD3FB 9.95%, #F5FFCB 48.86%, #9FFFDD 90.28%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1px;
    max-width: 310px;
    text-align: center;
}

.overlayResText {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.overlayResNumbContainer {
    background-color: #202750;
    padding: 4px 16px;
    margin-left: 11px;
    border-radius: 6px;
}

.overlayResNumb {
    color: #00FFDD;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
}

.zapPriceContainer {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}