
.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.streamsLeftText {
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
}
.renovationText {
    text-align: right;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: #6C5DD3CC;
}

@media (max-width: 600px) {
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .streamsLeftText {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0px;
    }
    .renovationText {
        text-align: left;
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        color: #6C5DD3CC;
    }
}