.container {
    /* background-color: #0D1021; */
}

.appBar {
    background-color: transparent !important;
    box-shadow: none !important;
}

.alreadyAUser {
    color: #D8D8D8;
}

.buttonContainer {
    margin-left: .5rem !important;
}

.button {
    color: #5F75EE !important;
    border: 1px solid #5F75EE !important;
    border-radius: 8px !important;
    font-weight: bold !important;
    text-transform: none !important;
    text-decoration: none !important;
}