@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.cdnfonts.com/css/mechsuit');


.Nav-Title {
  flex-grow: 1;
}

.White-Color {
  color: '#FFF';
  text-decoration: none;
  text-transform: none !important;
}

.Margin-Right {
  margin-left: 1rem;
}
body {
  font-family: 'Inter' !important;
}
p {
  font-family: 'Inter' !important;
  margin: 0;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #202750;
}

::-webkit-scrollbar-thumb {
  background: #4040FF;
}

::-webkit-scrollbar-thumb:hover {
  background: #8080FF;
}