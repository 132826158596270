.container {
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    height: 342px;
    border-radius: 20px;
    overflow: hidden;
    padding: 24px;
    justify-content: space-between;
}

.gradientContainer {
    padding: 4px;
    border-radius: 20px;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    flex: 1;
}

.costInput {
    display: flex;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    background-color: #0000;
    border: none;
    outline: none;
    height: 36px;
    border-radius: 8px;
    padding: 0px 8px;
    padding-left: 8px;
}

.costInput:focus {
    background-color: #14173544;
}

.button {
    display: flex;
    border-radius: 8px;
    padding: 8px 8px;
    /* min-width: 20px; */
    justify-content: center;
    min-height: 16px;
}

.button:hover {
    cursor: pointer;
}

.buttonText {
    color: #fff;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.492000013589859px;
    text-align: center;
}

.title {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
}

.subtitle {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
}